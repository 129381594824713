import React from 'react';
import styled from 'styled-components';
import ProductParam, {
  getDefaultConfigWithExtra,
  findExtraDisplayInfo,
  getProductSelectNames,
} from './ProductParam';

export default function LocalProductConfig(props) {
  const {product, config, setConfig} = props;
  const selectNames = getProductSelectNames(product);

  return (
    <Wrapper>
      {selectNames.map((selectName) => (
        <ProductParam
          key={selectName}
          selectName={selectName}
          param={product[selectName]}
          value={config[selectName]}
          setValue={(value) => {
            const extra = findExtraDisplayInfo(
              product[selectName],
              value,
              config,
            );

            if (selectName === 'side_select') {
              alert('由於您修改了單雙面設定, 請重新選擇其餘選項');
              const [nextConfigWithSide, nextExtra] = getDefaultConfigWithExtra(
                {
                  product,
                  side: value,
                },
              );
              setConfig(
                {
                  ...nextConfigWithSide,
                  side_select: value,
                },
                nextExtra,
              );
              return;
            } else if (product[selectName].type === 'master') {
              if (value.material !== config[selectName].material) {
                const [nextConfig, nextExtra] = getDefaultConfigWithExtra({
                  product,
                  material: value.material,
                });
                setConfig(
                  {
                    ...nextConfig,
                    side_select: config.side_select,
                  },
                  nextExtra,
                );
                return;
              }
            }

            setConfig(
              {
                ...config,
                [selectName]: value,
              },
              extra,
            );
          }}
          side={config['side_select']}
        />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div``;
