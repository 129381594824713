import React, {useState, useEffect, useContext} from 'react';
import {SelectRow} from '../../Components/ProductParam';
import ProductItem from '../../Components/ProductItem';
import {Context} from '../../AppContext';
import {Spin} from 'antd';
const slugify = require('uslug');

export default function RelatedProduct(props) {
  const {id} = props;
  const app = useContext(Context);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (id) {
        let resp = await app.actions.getProduct(id);
        setProduct(resp);
      }
      setLoading(false);
    })();
  }, [id, app.actions]);

  return loading ? (
    <Spin />
  ) : product ? (
    <SelectRow>
      <label>相關商品</label>
      <ProductItem
        horizontal
        product={product}
        onClick={() => {
          window.open(`/product/${slugify(product.name)}`, '_blank');
        }}
      />
    </SelectRow>
  ) : null;
}
