import React, {useState} from 'react';
import styled from 'styled-components';
import Image from '../../Components/Image';
import {Tab} from '../../Widgets';
import {Download} from '@styled-icons/feather';
import {downloadFile} from '../../Utils';
const config = require('../../data.json');

const {TabBar, TabItem, TabContent} = Tab;

const TABS = {
  INTRO: 0,
  SPEC: 1,
  NOTICE: 2,
  TEMPLATE: 3,
};

export default function BottomSection(props) {
  const [index, setIndex] = useState(TABS.INTRO);
  const {product} = props;
  if (!product) {
    return null;
  }

  return (
    <Wrapper>
      <TabBar>
        <TabItem selected={index === TABS.INTRO} onClick={() => setIndex(0)}>
          產品介紹
        </TabItem>
        <TabItem selected={index === TABS.SPEC} onClick={() => setIndex(1)}>
          產品規格
        </TabItem>
        <TabItem selected={index === TABS.NOTICE} onClick={() => setIndex(2)}>
          完稿須知
        </TabItem>
        <TabItem selected={index === TABS.TEMPLATE} onClick={() => setIndex(3)}>
          刀模下載
        </TabItem>
      </TabBar>

      <TabContent>
        {index === TABS.INTRO && (
          <div className="image-container">
            {product.images
              .filter((image) => image.name === 'intro')
              .map((img, idx) => (
                <Image
                  key={idx}
                  style={{width: '100%'}}
                  src={img.image}
                  linkto={img.link}
                />
              ))}
          </div>
        )}

        {index === TABS.SPEC && (
          <div className="image-container">
            {product.images
              .filter((image) => image.name === 'spec')
              .map((img, idx) => (
                <Image
                  key={idx}
                  style={{width: '100%'}}
                  src={img.image}
                  linkto={img.link}
                />
              ))}
          </div>
        )}

        {index === TABS.NOTICE && (
          <div className="image-container">
            {product.images
              .filter((image) => image.name === 'notice')
              .map((img, idx) => (
                <Image
                  key={idx}
                  style={{width: '100%'}}
                  src={img.image}
                  linkto={img.link}
                />
              ))}
          </div>
        )}

        {index === TABS.TEMPLATE &&
          product.files &&
          product.files.map((f, idx) => {
            return (
              <div
                style={{
                  margin: '15px 0',
                  display: 'flex',
                  alignItems: 'center',
                }}
                key={idx}>
                <div
                  style={{
                    backgroundColor: config.colors.main,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 40,
                    height: 40,
                    marginRight: 10,
                  }}>
                  <Download size={30} color="white" />
                </div>
                <div>
                  <div>{`${idx + 1}. ${f.name}`}</div>
                  <div
                    onClick={() => downloadFile(f.file)}
                    style={{
                      color: config.colors.main,
                      cursor: 'pointer',
                    }}>
                    {f.file.split('/').slice(-1)}
                  </div>
                </div>
              </div>
            );
          })}
      </TabContent>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border: 1px solid #ddd;
  background-color: white;
`;
