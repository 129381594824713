import React, {useState} from 'react';
import styled from 'styled-components';
import Lightbox from 'react-images';
import Image from '../../Components/Image';

export default function ProductImageLightbox(props) {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  const {css} = props;
  const images = props.images.filter((image) => image.name === 'top');

  if (images?.length > 0) {
    return (
      <Wrapper css={css}>
        <div className="thumbnails-wrapper">
          {images.map((d, i) => {
            return (
              <Image
                key={i}
                src={d.image}
                style={{
                  width: 164,
                  height: 106,
                  cursor: 'pointer',
                  flexShrink: 0,
                  marginRight: 10,
                }}
                background
                onClick={() => {
                  setSelected(i);
                  setOpen(true);
                }}
              />
            );
          })}
        </div>

        <Lightbox
          currentImage={selected}
          images={images.map((d) => ({src: `${d.image}`}))}
          isOpen={open}
          onClickPrev={(prev) => setSelected(prev - 1)}
          onClickNext={(prev) => setSelected(prev + 1)}
          onClose={() => setOpen(false)}
          showThumbnails={true}
          onClickThumbnail={(idx) => setSelected(idx)}
        />
      </Wrapper>
    );
  }

  return null;
}

const Wrapper = styled.div`
  & > .thumbnails-wrapper {
    display: flex;
    width: 100%;
    overflow: auto;
    justify-content: flex-start;
  }

  & > .thumbnails-wrapper.center {
    justify-content: center;
  }
  ${(props) => props.css}
`;
