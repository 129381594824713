import React from 'react';
import styled from 'styled-components';
const config = require('../../data.json');

export default function ContentWrapper({children}) {
  return (
    <Wrapper>
      <Center>{children}</Center>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 80px;
  background-color: white;

  .center-content {
  }

  @media only screen and (max-width: ${config.maxContentWidth}px) {
    padding: 20px;
  }
`;

export const Center = styled.div`
  max-width: ${config.maxContentWidth}px;
  margin: 0px auto;
`;
