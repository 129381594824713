import React, {useContext, useState, Fragment} from 'react';
import styled from 'styled-components';
import {Context} from '../AppContext';
import {Alert, message} from 'antd';
import {Input, Button, Divider, Text, Link, Row} from '../Widgets';
import Image from '../Components/Image';
const config = require('../data.json');

function errorHandler(ex) {
  console.warn(ex);

  // if(ex && ex.error === "user_not_found") {
  //   message.warning("查無此使用者");
  // }
}

export default function Login(props) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const app = useContext(Context);

  const login = async () => {
    if (!username || !password) {
      return;
    }
    setLoading(true);

    try {
      await app.actions.login({username, password});
      app.actions.getCart();
      app.actions.setModal(null);
    } catch (ex) {
      errorHandler(ex);
      setError(true);
    }
    setLoading(false);
  };

  return (
    <Wrapper>
      <Image
        src="/images/logo.svg"
        style={{width: 150, margin: 'auto', marginBottom: 30}}
      />

      {error && (
        <Alert
          message="登入失敗"
          description={'請重新確認您的信箱與密碼，然後再試一次'}
          type="error"
          showIcon
          style={{marginBottom: 10}}
        />
      )}

      <Row>
        <Input
          id="login-username-input"
          placeholder="信箱"
          value={username}
          style={{padding: 12, flex: 1, margin: 0}}
          onChange={(e) => {
            let value = e.target.value;
            setUsername(value);
            setError(null);
          }}
        />
      </Row>

      <Row>
        <Input
          id="login-password-input"
          placeholder="密碼"
          type="password"
          value={password}
          style={{padding: 12, flex: 1, margin: 0}}
          onChange={(e) => {
            let value = e.target.value;
            setPassword(value);
            setError(null);
          }}
        />
      </Row>

      <Button
        loading={loading}
        onClick={login}
        style={{alignSelf: 'stretch', marginBottom: 10}}>
        {' '}
        登入
      </Button>

      <Link
        to="/fgpass"
        onClick={() => app.actions.setModal(null)}
        style={{alignSelf: 'flex-end'}}>
        <Text size="xs" style={{opacity: 0.7}}>
          忘記密碼？
        </Text>
      </Link>

      <Divider>
        <Text size="sm">或</Text>
      </Divider>

      <Row style={{justifyContent: 'center'}}>
        <SocialSignInBtn css="background-color: #0183FF;">
          <Image
            linkto={`${config.endpoint.apiHost}/auth/facebook`}
            src="/images/socialsign-facebook.png"
            style={{width: 28, height: 28}}
          />
        </SocialSignInBtn>

        <SocialSignInBtn>
          <Image
            linkto={`${config.endpoint.apiHost}/auth/google`}
            src="/images/socialsign-google.png"
            style={{width: 28, height: 28}}
          />
        </SocialSignInBtn>
      </Row>

      <Divider />

      <Row style={{justifyContent: 'center'}}>
        <Text size="sm">還不是會員嗎？</Text>
        <Link
          to="/register"
          onClick={() => app.actions.setModal(null)}
          style={{color: config.colors.main}}>
          立刻註冊新帳號
        </Link>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
`;

const SocialSignInBtn = styled.div`
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  border: 1px solid ${config.colors.border};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;

  & img {
    width: 28px;
    height: 28px;
  }

  ${(props) => props.css || ''}
`;

const metadata = {
  title: false,
  width: '400px',
};

export {metadata};
