import React, {Component, useState} from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';
import Image, {ImageWrapper} from '../../Components/Image';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@styled-icons/material';

const RATIO = 420 / 640;

function renderControl(side) {
  switch (side) {
    case 'right':
      return ({nextSlide}) => (
        <IconContainer onClick={nextSlide}>
          <KeyboardArrowRight color="#4e4e4e" style={{width: 35, height: 35}} />
        </IconContainer>
      );
    case 'left':
      return ({previousSlide}) => (
        <IconContainer onClick={previousSlide}>
          <KeyboardArrowLeft color="#4e4e4e" style={{width: 35, height: 35}} />
        </IconContainer>
      );
    default:
      return () => false;
  }
}

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 2px;
`;

export default function ProductImageCarousel(props) {
  const [index, setIndex] = useState(0);
  const images = props.images.filter((image) => image.name === 'top');

  return (
    <Carousel
      autoplay={false}
      width={'100%'}
      initialSlideHeight={300}
      renderBottomCenterControls={renderControl()}
      renderCenterLeftControls={renderControl('left')}
      renderCenterRightControls={renderControl('right')}
      slideIndex={index}
      afterSlide={(index) => setIndex(index)}
      style={{outline: 'none'}}>
      {images?.length > 0 ? (
        images.map((d, i) => <Image key={i} ratio={RATIO} src={d.image} />)
      ) : (
        <div style={{width: '100%', height: 300, backgroundColor: '#f0f0f0'}} />
      )}
    </Carousel>
  );
}
